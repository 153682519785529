import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#prechladnutie"> &gt; Radca(PRECHLADNUTIE)</a>
            <a
              href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/"
              className="sel"
            >
              {" "}
              &gt; Štyri fázy vzniku prechladnutia
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_87834502.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Štyri fázy vzniku prechladnutia</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Výskumy potvrdzujú, že v jesennom a zimnom období každého z
                    nás minimálne raz postihne{" "}
                    <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                      prechladnutie
                    </a>
                    . Spavosť, skľúčenosť, rozladenie, upchatý nos, škrabanie v
                    hrdle a oslabenie sú prvými prejavmi prichádzajúceho
                    ochorenia. Čo ešte by malo vzbudiť našu pozornosť? S akými
                    príznakmi ochorenia treba ísť k lekárovi? Spoznajte štyri
                    fázy vzniku prechladnutia, aby ste vedeli účinne zatočiť s{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekciami
                    </a>
                    .{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Prechladnutie možno rozdeliť na štyri fázy. Príznaky
                  prejavujúce sa v prvej fáze sú: celkové rozladenie, zimnica,
                  nepohoda a únava. Takéto príznaky nás často ani netrápia,
                  pretože príčin poklesu energie môže byť veľa, a nie každý si
                  uvedomí, že organizmus práve vstúpil do vojny s vírusmi. Na
                  posilnenie{" "}
                  <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                    imunitného systému
                  </a>
                  &nbsp;siahneme po vitamíne C alebo využijeme domáce spôsoby
                  rozohriatia organizmu, napr. si urobíme čaj s medom, cesnakom
                  alebo zázvorom. Niektorí z nás si ľahnú, odpočívajú a snažia
                  sa poriadne vyspať. Tieto metódy však nie vždy pomôžu.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Druhá fáza je moment, kedy nás náš stav začne znepokojovať.
                  Bolí nás hlava, máme upchatý nos, pociťujeme škrabanie v hrdle a trápi nás podráždená{" "}
                  <a href="/slovnik_pojmov/#nosova_sliznica" target="_blank">
                    nosová sliznica
                  </a>
                  &nbsp;, v ktorej sa rozvíja zápal. Znamená to, že na
                  organizmus zaútočil niektorý z 200 vírusov spôsobujúcich{" "}
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    prechladnutie
                  </a>
                  . Objavujú sa ťažkosti s dýchaním a vykašliavaním hlienov.
                  Silný, vodnatý a bezfarebný výtok z nosa sťažuje vykonávanie
                  bežných denných činností. Opuchnutá nosová sliznica upcháva
                  nos, takže sa nám zmení hlas. Nadmerné množstvo hlienov v
                  tejto fáze steká do hrdla, čo môže viesť k sekundárnej nákaze.
                  Aby sme sa zbavili nechceného hosťa, treba s ním rýchlo začať
                  bojovať. Upchatý nos a prinosové dutiny možno prečistiť
                  niekoľkými spôsobmi. Jedným z nich je používanie liekov vo
                  forme roztokových aerodisperzií obsahujúcich rôzne liečivá.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    Xylometazolín
                  </a>
                  &nbsp;obsiahnutý v liekoch{" "}
                  <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                  </a>{" "}
                  a OLYNTH® PLUS 1 mg/50 mg/ml zmierňuje opuch nosovej sliznice. Ak máme vysušenú nosovú
                  sliznicu, bude lepším riešením{" "}
                  <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                  </a>
                  , ktorý zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Štyri fázy nachladnutie a riešenie OLYNTH® na príznaky inkce."
                    src="/web/kcfinder/uploads/images/1226065553.jpg"
                    style={{
                      height: "170px",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid rgb(255, 255, 255)",
                      width: "250px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Tretia fáza prechladnutia sa prejavuje zvýšením telesnej
                  teploty (subfebrilný stav) a kašľom. Približne po piatich až
                  šiestich dňoch prechladnutia sa objavia bolesti svalov a
                  kĺbov. Sprevádza ich pocit „lámania v kostiach“ a bolesť
                  celého tela. Oslabenie organizmu komplikuje bežné denné
                  fungovanie, pri predklone sa objavujú nepríjemné pocity,
                  jednoduché činnosti vykonávame oveľa dlhšie, športovanie je
                  nemožné. Trápenie sa so zvýšenou telesnou teplotou, zrýchleným
                  tepom, potením sa a začervenaním – všetky tieto príznaky
                  svedčia o tom, že stav sa zhoršuje. V takej situácii je
                  potrebné poradiť sa s lekárom.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  V štvrtej fáze prechladnutia naďalej bojujeme so silnou
                  nádchou a prekrvením nosovej sliznice, príznaky však pomaly
                  ustupujú. Vyzdravenie obvykle prichádza približne po 8 až 9
                  dňoch. Vráti sa nám lepšia nálada, energia, pominie nádcha a
                  začneme hovoriť svojím hlasom. V podráždenom hrdle nás pomaly
                  prestane škriabať, nosová sliznica postupne odpúcha, zmizne aj
                  horúčka a kašeľ a vracajú sa nám sily.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:</span>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Deep Dive into Upper Respiratory Health in Poland” Incite,
                    2011 – spotrebiteľský prieskum pre Johnson and Johnson.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Tylżanowska-Kisiel Joanna, Przeziębienie i grypa, Helion,
                    2007.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg)"}} />
                  <span className="title">
                    Domáce spôsoby boja s prechladnutím
                  </span>
                </a>
                <a
                  href="/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/88688757.jpg)"}} />
                  <span className="title">
                    Ako sa ochrániť pred prechladnutím?
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
